<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1">
        <form class="text-center p-5" style="margin-top:70px;height:auto;padding-top:100px !important;" @submit.prevent="registerUser">
          <input type="text" id="name" class="form-control mb-5" placeholder="Name" v-model="register.name" required />
          <input type="email" id="email" class="form-control mb-5" placeholder="Email" v-model="register.email" required />
          <input type="password" id="password" class="form-control mb-5" placeholder="Password" v-model="register.password" />
          <input type="text" id="company" class="form-control mb-5" placeholder="CompanyID" v-model="register.company" required />
          <b-form-group>
            <b-form-checkbox v-model="register.admin">Admin</b-form-checkbox>
            <b-form-checkbox v-model="register.godUser">God User</b-form-checkbox>
          </b-form-group>
          <p>
            <center>
              <button class="btn btn-primary btn-block w-75 my-4" type="submit">Sign in</button>
            </center>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "register",
    data() {
        return {
            register: {
                name: "",
                email: "",
                password: "",
                company: "60145f77652087548edccea4",
                admin: false,
                godUser: false,
                status: "active"
            }
        }
    },
    methods: {
        async registerUser() {
            try {
                let response = await this.$axios.post("/auth/register", this.register)
                console.log(response)
                let token = response.data.token
                if (token) {
                    localStorage.setItem("jwt", token);
                    //this.$router.push("/dashboard");
                    console.log("Success : Registration Was successful")
                } else {
                    console.log("Error: Something Went Wrong")
                }
            } catch (err) {
                console.log(err.response)
            } 
        }
    }
}
</script>